import { AttachmentIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  InputGroup,
  Text,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'

export const FormFileUpload = ({
  label,
  name,
  rules,
  defaultValue,
  accept,
  multiple,
  textButton = '',
  textDescription = 'Currently support .ogg , .mp3, .wav less than 5mb',
  isRequired,
  wrapperProps,
  handleDragImage,
  disabled = false,
  ...rest
}) => {
  const { control } = useFormContext()
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue })
  const inputRef = useRef(null)
  const [dragActive, setDragActive] = useState(false)

  const handleClick = () => inputRef.current?.click()

  const handleOnChange = (e) => {
    onChange(e.target.files)
  }

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleDragImage(e.dataTransfer.files)
    }
  }

  return (
    <FormControl
      maxW='100%'
      w='auto'
      isInvalid={error?.message}
      {...wrapperProps}
    >
      <div
        onDragEnter={handleDrag}
        style={{
          position: 'relative',
          height: 'auto',
          width: '100%',
        }}
      >
        {label && (
          <FormLabel whiteSpace='nowrap' color="#000000">
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
          </FormLabel>
        )}
        <InputGroup onClick={handleClick}>
          <input
            type={'file'}
            multiple={multiple || false}
            hidden
            ref={inputRef}
            accept={accept}
            onChange={handleOnChange}
            {...rest}
          />
          <>
            <Button
              border='1px solid #EEEFF2'
              backgroundColor='#F9FAFB'
              borderRadius='3px'
              w='100%'
              height="60px"
              position='relative'
              leftIcon={<Icon as={AttachmentIcon} />}
              disabled={disabled}
            >
              <span 
                style={{
                  width: '213px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#000000'
                }}
              >{textButton ? textButton : 'Drop items here or upload file'}</span>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    // borderRadius: '100%',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                ></div>
              )}
            </Button>
          </>
        </InputGroup>
        <Text textAlign={'center'} fontSize='xs' mt={1} color="#000000">
          <span
            style={{
              color: 'red',
            }}
          >
            *
          </span>{' '}
          {textDescription}
        </Text>
      </div>
    </FormControl>
  )
}

// const App = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm()
//   const onSubmit = handleSubmit((data) => console.log('On Submit: ', data))

//   const validateFiles = (value) => {
//     if (value.length < 1) {
//       return 'Files is required'
//     }
//     for (const file of Array.from(value)) {
//       const fsMb = file.size / (1024 * 1024)
//       const MAX_FILE_SIZE = 10
//       if (fsMb > MAX_FILE_SIZE) {
//         return 'Max file size 10mb'
//       }
//     }
//     return true
//   }

//   return (
//     <>
//       <form onSubmit={onSubmit}>
//         <FormFileUpload
//           accept={'image/*'}
//           multiple
//           register={register('file_', { validate: validateFiles })}
//         />

//         <button>Submit</button>
//       </form>
//     </>
//   )
// }
