import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
// import ConditionRuleEventManagement from "./condition";
import LeaderBoardRow from "./row";
import ButtonType from "components/Button";
import { getSkillRankApi, updateSkillRankApi } from "api/skillRank.api";
import useWithToast from "hooks/useWithToast";

export default function ModalRankDistribution({
  isOpen,
  onClose,
}) {
  const labels = [
    "#",
    "Title Skill",
    "Rank Distribution",
  ];
  
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const [rankDistribute, setRankDistribute] = useState([]);
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);

  const getSkillRank = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const { data } = await getSkillRankApi({
          page: 1,
          limit: 999999,
        });
        if (data?.success) {
          const res = data?.data;
          setRankDistribute(res?.records);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    getSkillRank();
  }, [getSkillRank]);


  const handleChangeRank = (id, field, value) => {
    setRankDistribute((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const calculateTotal = useMemo(() => {
    return rankDistribute.reduce((sum, item) => sum + item.rank_percentage, 0);
  }, [rankDistribute]);

  const handleSaveRank = async () => {
    if (calculateTotal !== 100) {
      toast({
        description: "Total Rank Distribution Is Equal 100",
        status: "error",
        position: "bottom-right",
      });
    } else {
      try {
        setIsLoading(true);
        const params = rankDistribute?.map((rank) => ({
          id: rank?.id,
          title_skill_rank: rank?.title_skill_rank,
          rank_percentage:  rank?.rank_percentage,
          logo_skill_rank_url:  rank?.logo_skill_rank_url
        }))
        const { data } = await updateSkillRankApi(params);
        if (data?.success) {
          showToastSuccess({
            title: "Successfully edited XP Reward Hunter.",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Rank Distribution
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px" pt="0px">
              <CardBody overflowX="auto">
                <TableCustom
                  isCheck={false}
                  labels={labels}
                  dataRow={rankDistribute}
                  isLoading={isLoading}
                >
                  <>
                    {rankDistribute?.map((item, index) => {
                      return (
                        <LeaderBoardRow
                          key={item?.id}
                          item={item}
                          index={index}
                          handleChangeRank={handleChangeRank}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            onClick={handleSaveRank}
            disabled={isLoading || !rankDistribute?.length}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
  