import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { MdEdit } from "react-icons/md";

export default function LeaderBoardRow({
  item,
  index
}) {
  const textColor = useColorModeValue("#000000", "white");

  const convertTime = (time) => {
    return moment(time * 1000).utc().format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_id}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user?.email}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.total_turn}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.total_turn}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.total_point}
        </Text>
      </Td>
    </Tr>
  );
}
